import React, { useEffect, useRef, useState } from 'react';

import Link from 'next/link';
import { isEmpty } from 'lodash';
import { cn } from '../../utils';
import Image from 'next/image';

type Props = {
  imgSrc: string;
  title: string;
  ctaText: string;
  ctaUrl: string;
};

const HeroSection = (props: Props) => {
  const {
    imgSrc,
    title = 'Share a taste of the good life',
    ctaText = '',
    ctaUrl = '',
  } = props;

  const [desktopImg, mobileImg] = imgSrc.trim().split(',');

  return (
    <div>
      {/* Hero card */}
      <div className="relative  mx-auto max-w-full ">
        <Link href={!isEmpty(ctaUrl) ? ctaUrl : '#'} prefetch={false}>
          {!isEmpty(desktopImg) && (
            <Image
              priority
              fill
              src={isEmpty(desktopImg) ? '/RUOK-Banner.jpg' : desktopImg}
              alt={title}
              className={cn(
                !isEmpty(mobileImg) ? 'hidden lg:block' : '',
                !isEmpty(title) && !isEmpty(ctaText)
                  ? '!h-[300px] !md:h-[100%] object-cover object-top !static'
                  : 'object-contain object-top !static',
              )}
            />
          )}
        </Link>
        <div
          className={cn(
            'absolute flex flex-col items-center gap-y-5  left-1/2 transform -translate-x-1/2 -translate-y-1/2',
            !isEmpty(title) && !isEmpty(ctaText)
              ? 'top-[50%] md:top-[70%]'
              : 'top-[70%]',
          )}
        >
          {!isEmpty(title) && (
            <h1 className="text-center text-white font-black  text-shadow leading-[150%] hover:underline ">
              <Link
                href={ctaUrl}
                prefetch={false}
                className="font-playfairdisplay italic text-[34px] font-extralight"
              >
                {title}
              </Link>
            </h1>
          )}
          {!isEmpty(ctaText) && (
            <Link
              href={ctaUrl}
              prefetch={false}
              className="w-fit button-gold-solid uppercase text-sm py-3 px-6"
            >
              {ctaText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
